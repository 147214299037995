import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import {
  createControllerId,
  createLogger,
} from '../../common/controller/helpers';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { createMonitoring } from '../../common/services/create-monitoring';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import getEnvironment from '../../common/services/get-environment';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToInstanceChange from '../../common/services/listen-to-instance-change';
import { simulateControllerError } from '../../common/services/simulate-error';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { AppStore } from '../../common/types';
import { createReduxStore } from './create-redux-store';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';

const isProduction = process.env.NODE_ENV === 'production';

export const createCategoryMenuController: CreateControllerFn = ({
  controllerConfig: {
    appParams,
    compId,
    config,
    setProps,
    wixCodeApi,
    platformAPIs,
  },
  flowAPI,
}) => {
  const { isSSR, isDebug } = getEnvironment(wixCodeApi);

  const { fedopsLogger } = createMonitoring(flowAPI);

  const language = getCurrentSiteLanguage(wixCodeApi, flowAPI);

  const log = createLogger(isDebug, isProduction);

  log('createCategoryMenuController', {
    appParams,
    wixCodeApi,
    isSSR,
    language,
  });

  let store: AppStore;

  const pageReady = async () => {
    log('createCategoryMenuController.pageReady -> start');

    simulateControllerError(wixCodeApi, 'category-menu.pageReady');

    const bundleName = 'category-menu-widget';

    initLazyActions({ isSSR });

    store = createReduxStore({
      appParams,
      wixCodeApi,
      compId,
      fedopsLogger,
      bundleName,
      flowAPI,
    });
    const actions = initializeActions({
      wixCodeApi,
      store,
      fedopsLogger,
    });
    const actionsPromisified = initializePromisifiedActions({
      store,
    });

    await initializeStoreBaseData({
      wixCodeApi,
      store,
      language,
      platformAPIs,
      config,
      bundleName,
      appParams,
      flowAPI,
    });
    listenToInstanceChange(wixCodeApi, appParams, store);

    log('createCategoryMenuController.pageReady -> done');
    const state = store.getState();

    const stateVersions = getInitialStateVersions(state);
    const controllerId = createControllerId();

    setProps({
      state,
      stateVersions,
      actions,
      actionsPromisified,
      isSSR,
      isRTL: isRtlLanguage(language),
      controllerId,
      fitToContentHeight: true,
    });

    refreshDataOnLogin({ wixCodeApi, store, router: undefined });
    subscribeToChange(store, stateVersions, setProps, controllerId);
  };

  return {
    pageReady,
    exports: () => {},
    updateConfig: (_$w, { style: { styleParams } }) => {
      store && store.dispatch(setAppSettings({ style: styleParams }));
    },
  };
};
