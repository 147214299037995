import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { fetchTPASettings } from '@wix/communities-blog-client-common';
import { OOI_EXPERIMENTS } from '../../../experiments';
import { initWixData } from '../../common/actions/init-wix-data';
import { getInstance } from '../../common/controller/helpers';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setCommunitiesContext } from '../../common/store/communities-context/communities-context-actions';
import { setCustomRoutes } from '../../common/store/custom-routes/custom-routes-actions';
import { initializeInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import {
  setLocale,
  setQueryLocaleAction,
} from '../../common/store/locale/locale-actions';
import { AppStore, ControllerConfig } from '../../common/types';
import { fetchInitialData } from '../actions/fetch-initial-data';
import { POST_LIST_WIDGET_BUNDLE_NAME } from '../constants/post-list-widget';

export {
  initializeActions,
  initializePromisifiedActions,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

interface InitializeStoreBaseDataParams {
  controllerConfig: ControllerConfig;
  store: AppStore;
  flowAPI: ControllerFlowAPI;
}

export async function initializeStoreBaseData({
  controllerConfig,
  store,
  flowAPI,
}: InitializeStoreBaseDataParams) {
  const { wixCodeApi, appParams, config, platformAPIs } = controllerConfig;
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();
  const language = getCurrentSiteLanguage(wixCodeApi, flowAPI);

  store.dispatch(initializeInstanceValues(getInstance(wixCodeApi)()));

  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(wixCodeApi, appParams));

  const shouldUseV2 = flowAPI.essentials.experiments.enabled(
    OOI_EXPERIMENTS.USE_SETTINGS_V2,
  );

  await Promise.all([
    store.dispatch(
      initWixData({
        viewMode,
        dataKeys: ['categoryId', 'tagId'],
        config,
      }),
    ),
    store.dispatch(
      fetchTPASettings({
        language:
          (shouldUseV2 && wixCodeApi.window.multilingual.isEnabled) ||
          !shouldUseV2
            ? language
            : undefined,
        shouldUseV2,
        shouldUseFallbackOnError: true,
      }),
    ),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
    store.dispatch(
      setBasicParams({
        viewMode,
        language,
        biPageNumber: platformAPIs?.bi?.pageNumber,
      }),
    ),
    store.dispatch(setAppConfig({ bundleName: POST_LIST_WIDGET_BUNDLE_NAME })),
    store.dispatch(setCustomRoutes()),
  ]);

  await store.dispatch(fetchInitialData());
}
